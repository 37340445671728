.aug-2021 {
  .aboutSpeaker-container {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    row-gap: 2rem;
    width: 100% !important;
    justify-content: center;
  }
}

@media screen and (max-width: 981px) {
  .aug-2021 {
    .aboutSpeaker-container {
      width: 100% !important;
      margin: auto;
      justify-content: center !important;
    }
  }
}
