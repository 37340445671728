$aboutHedaingColor: #636363;
$borderBottomColor: #4faaaa;

.dec-2023 {
  .about {
    width: 80%;
    margin: auto;
  }
  .aboutHeadingBox {
    padding-top: min(15%, 8rem);
  }
  .aboutHeading {
    color: $aboutHedaingColor;
    width: fit-content;
    margin: 0 auto;
    border-bottom: 5px solid $borderBottomColor;
  }
  .aboutHeadingText {
    color: $aboutHedaingColor;
    line-height: 1.5 !important;
  }
  .aboutSubheadingText {
    color: $aboutHedaingColor;
    line-height: 1.5 !important;
  }
  .aboutButton {
    border-radius: 100rem;
    font-family: "Roboto";
    padding-inline: max(3%, 40px);
    color:white;

    &:hover {
      background-color: #303080 !important;
    }
  }
}
