.mobileNavParent {
  height: 100vh !important;
}
.navButton {
  font-size: 4rem !important;
}
.navText {
  font-size: 1.7rem !important;
  text-transform: uppercase;
  min-width: max-content;
}
