.dec-2023 {
  .eventInfo {
    background: linear-gradient(180deg, #35B2B2 0%, #117878 72.58%, #005151 100%);
    margin-top: min(15%, 8rem);
  }

  .eventInfoSubtext {
    font-weight: 500 !important;
  }
}
