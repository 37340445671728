$black: #000000;
.dec-2023 {
  .social-container {
    min-height: 10rem;
  }

  .social-wrapper {
    color: $black;
    &:hover {
      color: #217dde !important;
    }
  }
  .socialText {
    font-size: 2.5rem;
    font-weight: 400;
  }
}

@media screen and (max-width: 768px) {
  .dec-2023 {
      .socialText {
      font-size: 2rem;
    }
  }
}