$aboutHedaingColor: #636363;
$borderBottomColor: #00a1f1;

.aug-2021 {
  .about {
    width: 100%;
    margin: auto;
  }
  .aboutHeadingBox {
    padding-top: min(15%, 8rem);
  }
  .aboutHeading {
    color: $aboutHedaingColor;
    width: fit-content;
    margin: 0 auto;
    border-bottom: 5px solid $borderBottomColor;
  }
  .one-day-event {
    // font-size: 2.8125rem;
    font-size: 4rem;
    padding-top: 5rem;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -40px;
      bottom: -40px;
      width: 80px;
      height: 2px;
      background-color: #00a1f1;
    }
    span {
      color: $borderBottomColor;
    }
  }
  .aboutHeadingText {
    color: $aboutHedaingColor;
    line-height: 1.5 !important;
  }
  .aboutSubheadingText {
    color: $aboutHedaingColor;
    line-height: 1.5 !important;
  }
  .aboutButton {
    border-radius: 100rem;
    font-family: "Roboto";
    font-weight: 400;
    text-transform: none;
    padding-inline: max(3%, 40px);
    color:white;
    &:hover {
      background-color: #00a1f1 !important;
    }
  }
}
