$sponsorHeadingColor: #636363;
$borderBottomColor: #5eb3d8;
$diamondColor: #5eb3d8;
$platinumColor: #9653ca;
$goldColor: #eaaf0e;

.aug-2021 {
  .sponsorsHeadingBox {
    padding-top: min(15%, 8rem);
  }
  .sponsorHeading {
    color: $sponsorHeadingColor;
    width: fit-content;
    margin: 0 auto;
    border-bottom: 5px solid $borderBottomColor;
  }
  .sponsorSubHeading {
    width: fit-content;
    margin: 0 auto;
  }
  .diamond {
    color: $diamondColor;
    border-bottom: 5px solid $diamondColor;
  }
  .platinum {
    color: $platinumColor;
    border-bottom: 5px solid $platinumColor;
  }
  .gold {
    color: $goldColor;
    border-bottom: 5px solid $goldColor;
  }

  .sponsor-wrapper {
    margin-top: 5rem;
  }
  .sponsor-border {
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.25);
    border-radius: 7px;
  }
  .sponsor-img {
    object-fit: contain;
    padding: 5rem;
  }
}
